// IIFE called when script is loaded
// Immediately invoked function expression
( function initModule() {
    // CSSClasses

    const CSS_CLASS_TABBAR = 'tabbar';
    const CSS_CLASS_TABBAR_WRAPPER = 'tabbar-container';
    const CSS_CLASS_TABBAR_PREV = 'tabbar-btn-prev';
    const CSS_CLASS_TABBAR_NEXT = 'tabbar-btn-next';
    const CSS_CLASS_TABBAR_LIST = 'tabbar-list';

    const tabbars = [ ...document.querySelectorAll( `.${CSS_CLASS_TABBAR}` ) ];

    if ( !tabbars ) return;

    let state = {};

    /**
     * Sets the new state
     * @param {object} nextState - Object for the new state
     * @return {object} returns the new state
     */
    const setState = nextState => {
        const newState = Object.assign( {}, state, nextState );

        state = newState;

        return newState;
    };

    /**
     * Sets the initial state object
     */
    const initialState = {
        shift: 0,
        currItemIndex: 0
    };

    /**
     * Meant to be used as event listener callback.
     * Sets active class of currently selected tab.
     * @param {object} currTab - Tab node which fired the event
     * @param {array} tabs - Array of tab nodes
     * @param {array} panes - Array of tab-pane nodes
     * @return {undefined}
     */
    const setActiveTab = ( currTab, tabs, panes ) => {
        const tabTargetId = currTab.querySelector( 'a' ).hash;
        const currPane = panes.filter( pane => `#${pane.id}` === tabTargetId );

        tabs.forEach( tab => {
            tab.classList.remove( 'active' );
        } );

        panes.forEach( pane => {

            pane.classList.remove( 'in' );

            setTimeout( () => {
                pane.classList.remove( 'active' );

                currPane.forEach( pane => {
                    pane.classList.add( 'active' );

                    setTimeout( () => {
                        pane.classList.add( 'in' );
                    }, 150 );

                } );
            }, 150 );

        } );

        currTab.classList.add( 'active' );

    };

    /**
     * Checks if the slide controls are visible or not
     * @param {object} tabbar - Tabbar on which controls should be checked
     * @param {array} tabs - Array of tab nodes
     * @param {object} tabList - The tab list node with all the tabs
     * @return {undefined}
     */
    const checkSlideControls = ( tabbar, tabs, tabList ) => {
        const wrapper = tabbar.querySelector( `.${CSS_CLASS_TABBAR_WRAPPER}` );

        const tabListItemsWidth = tabs.reduce( ( acc, tab ) => {
            return acc + tab.clientWidth;
        }, 2 );

        tabList.style.width = `${tabListItemsWidth}px`;

        const itemsFillContainer = wrapper.clientWidth < tabListItemsWidth;

        if ( itemsFillContainer ) {
            wrapper.classList.add( 'show-controls' );
        } else {
            wrapper.classList.remove( 'show-controls' );
            tabList.style.transform = 'translateX(0)';

            // Reset state
            setState( initialState );
        }
    };

    /**
     * Handles right button click to move tab list
     * @param {array} tabs - Array of tab nodes
     * @param {object} tabList - The tab list node with all the tabs
     * @return {undefined}
     */
    const moveRight = ( tabs, tabList ) => {
        const { currItemIndex, shift } = state;
        const wrapper = document.querySelector( `.${CSS_CLASS_TABBAR_WRAPPER}` );

        const isLastItem = currItemIndex === tabs.length - 1;
        const shiftIsNegative = shift < 0;
        const newShift = shiftIsNegative
            ? -( shift - tabs[ currItemIndex ].clientWidth )
            : shift + tabs[ currItemIndex ].clientWidth;

        const newTabListWidth = tabList.clientWidth - shift;
        const isSmallerThanWrapper = newTabListWidth < wrapper.clientWidth - 96;

        if ( isLastItem || isSmallerThanWrapper ) {

            // Reset state
            setState( initialState );
            tabList.style.transform = 'translateX(0)';

            return;
        }

        const nextListItem = currItemIndex + 1;

        setState( {
            shift: newShift,
            currItemIndex: nextListItem
        } );

        tabList.style.transform = `translateX( ${-newShift}px )`;
    };

    /**
     * Handles left button click to move tab list
     * @param {array} tabs - Array of tab nodes
     * @param {object} tabList - The tab list node with all the tabs
     * @return {undefined}
     */
    const moveLeft = ( tabs, tabList ) => {
        const { shift, currItemIndex } = state;
        const wrapper = document.querySelector( `.${CSS_CLASS_TABBAR_WRAPPER}` );

        const isFirstItem = currItemIndex === 0;

        if ( isFirstItem ) {

            // Set index to last item of the list
            setState( { currItemIndex: tabs.length - 1 } );

            const reverseTabs = tabs.slice().reverse();

            const tabListWidth = reverseTabs.reduce( ( acc, tab, i, list ) => {
                const isLastTab = i === list.length - 1;

                if ( isLastTab ) {
                    return acc.sum;
                }

                if ( acc.done ) {
                    return acc;
                }

                const nextTab = list[ i + 1 ];
                const sumWithNext = acc.sum + tab.clientWidth + nextTab.clientWidth;


                const isLargerThanWrapper = sumWithNext >= wrapper.clientWidth - 96;

                const sum = acc.sum + tab.clientWidth;

                if ( isLargerThanWrapper ) {
                    return { sum, done: true };
                }

                setState( { currItemIndex: state.currItemIndex - 1 } );

                return { sum, done: false };
            }, { sum: 0, done: false } );

            const newShift = tabList.clientWidth - tabListWidth - 108;

            setState( { shift: newShift } );

            tabList.style.transform = `translateX( ${-newShift}px )`;

            return;
        }

        const prevListItem = tabs[ currItemIndex - 1 ];
        const shiftIsPositive = shift > 0;

        const newShift = shiftIsPositive
            ? -( shift - prevListItem.clientWidth )
            : shift + prevListItem.clientWidth;

        const prevItemIndex = currItemIndex - 1;

        setState( {
            shift: newShift,
            currItemIndex: prevItemIndex
        } );

        tabList.style.transform = `translateX( ${newShift}px )`;
    };

    /**
     * Adds eventlisteners to buttons
     * @param {object} tabbar - The tabbar with tabs
     * @param {array} tabs - Array of tab nodes
     * @param {object} tabList - The tab list node with all the tabs
     * @return {undefined}
     */
    const addButtonEventListeners = ( tabbar, tabs, tabList ) => {
        const tabPrevBtn = tabbar.querySelector( `.${CSS_CLASS_TABBAR_PREV}` );
        const tabNextBtn = tabbar.querySelector( `.${CSS_CLASS_TABBAR_NEXT}` );

        tabNextBtn.addEventListener( 'click', () => moveRight( tabs, tabList ) );
        tabPrevBtn.addEventListener( 'click', () => moveLeft( tabs, tabList ) );
    };

    // Initializes tabbars
    tabbars.forEach( tabbar => {
        const tabList = tabbar.querySelector( `.${CSS_CLASS_TABBAR_LIST}` );
        const tabListItems = [ ...tabList.children ];
        const tabContent = tabbar.nextElementSibling;
        const tabContentPanes = [ ...tabContent.querySelectorAll( '.tab-pane' ) ];

        // Set initial state
        setState( initialState );

        checkSlideControls( tabbar, tabListItems, tabList );

        // Add tab event listeners
        tabListItems.forEach( item => {
            item.addEventListener( 'click', (e) => {
                e.preventDefault();
                setActiveTab( item, tabListItems, tabContentPanes );
            });
        } );

        addButtonEventListeners( tabbar, tabListItems, tabList );

        //window.onload = checkSlideControls( tabbar, tabListItems, tabList );
        window.addEventListener( 'resize', () => {
            checkSlideControls( tabbar, tabListItems, tabList );
        } );
    } );
} )();
